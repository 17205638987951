/* FilterPanel.js */
import React, { useState, useMemo, useEffect } from 'react';
import { View, Text, StyleSheet, TouchableOpacity, ScrollView, Platform, TextInput } from 'react-native';
import { Checkbox, Chip } from 'react-native-paper'; // UPDATED: Use Chip for multiple selections

const FilterPanel = ({ filters, onFilterChange, products }) => {
  const [expandedSection, setExpandedSection] = useState(null);

  // Extract unique categories and vendors from products
  const { categories, vendors } = useMemo(() => {
    const categoriesSet = new Set(products.map(product => product.category).filter(Boolean));
    const vendorsSet = new Set(products.map(product => product.vendorName).filter(Boolean));
    return {
      categories: Array.from(categoriesSet).sort(),
      vendors: Array.from(vendorsSet).sort()
    };
  }, [products]);

 // Set default category and vendor if not already set (UPDATED CODE)
    useEffect(() => {
        if (categories.length > 0 && vendors.length > 0 && filters.category.length === 0 && filters.vendor.length === 0) {
        onFilterChange({
            ...filters,
            category: [categories[0]],
            vendor: [vendors[0]]
        });
        }
    }, [categories, vendors, filters, onFilterChange]);

//console.log(`Default category`, filters.category)
//console.log(`Default vendor`, filters.vendor)

  const toggleSection = (section) => {
    setExpandedSection(expandedSection === section ? null : section);
  };

  // Updated Handlers to Properly Trigger State Changes
  const handleCategoryChange = (category) => {
    const updatedCategories = filters.category.includes(category)
      ? filters.category.filter(c => c !== category)
      : [...filters.category, category];
    onFilterChange({ ...filters, category: updatedCategories });
  };

  const handleVendorChange = (vendor) => {
    const updatedVendors = filters.vendor.includes(vendor)
      ? filters.vendor.filter(v => v !== vendor)
      : [...filters.vendor, vendor];
    onFilterChange({ ...filters, vendor: updatedVendors });
  };

  const renderChipSection = (title, items, selectedItems, onItemChange) => (
    <View style={styles.section}>
      <TouchableOpacity
        style={styles.sectionHeader}
        onPress={() => toggleSection(title)}
      >
        <Text style={styles.sectionTitle}>{title}</Text>
        <Text style={styles.chevron}>
          {expandedSection === title ? '▼' : '▶'}
        </Text>
      </TouchableOpacity>
      {expandedSection === title && (
        <ScrollView style={styles.sectionContent} contentContainerStyle={styles.chipContainer}>
          {items.map((item, index) => (
            <Chip
              key={index}
              selected={selectedItems.includes(item)}
              onPress={() => onItemChange(item)}
              //  style={styles.chip}
              //textStyle={styles.chipText} 
              style={[
                styles.chip,
                selectedItems.includes(item) ? styles.selectedChip : null
              ]}
              textStyle={[
                styles.chipText,
                selectedItems.includes(item) ? styles.selectedChipText : null
              ]}
              selectedColor="#000000" // This will make the check icon black              

            >
              {item}
            </Chip>
          ))}
        </ScrollView>
      )}
    </View>
  );

  const renderPriceSection = () => (
    <View style={styles.section}>
      <TouchableOpacity
        style={styles.sectionHeader}
        onPress={() => toggleSection('Max Price')}
      >
        <Text style={styles.sectionTitle}>Max Price</Text>
        <Text style={styles.chevron}>
          {expandedSection === 'Max Price' ? '▼' : '▶'}
        </Text>
      </TouchableOpacity>
      {expandedSection === 'Max Price' && (
        <View style={styles.sectionContent}>
          <TextInput
            style={styles.textInput}
            placeholder="Enter max price"
            value={filters.maxPrice}
            keyboardType="numeric"
            onChangeText={(value) => onFilterChange({ ...filters, maxPrice: value })}
          />
        </View>
      )}
    </View>
  );

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <Text style={styles.headerTitle}>🔍 Filters</Text>
      </View>
      {/* Display Selected Filters (NEW CODE) */}
      <View style={styles.selectedFiltersContainer}>
        <Text style={styles.selectedFiltersTextTitle}>Selected Categories:</Text>
        <Text style={styles.selectedFiltersText}>{filters.category.length > 0 ? filters.category.join(', ') : 'None'}</Text>
        <Text style={styles.selectedFiltersTextTitle}>Selected Stores:</Text>
        <Text style={styles.selectedFiltersText}>{filters.vendor.length > 0 ? filters.vendor.join(', ') : 'None'}</Text>
        <Text style={styles.selectedFiltersTextTitle}>Max Price:</Text>
        <Text style={styles.selectedFiltersText}>{filters.maxPrice || 'None'}</Text>

      </View>

      <ScrollView>
        {renderChipSection('Categories', categories, filters.category, handleCategoryChange)}
        {renderChipSection('Stores', vendors, filters.vendor, handleVendorChange)}
        {renderPriceSection()}
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
    container: {
        backgroundColor: 'lightblue',
        padding: 16,
        borderRadius: 8,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.1,
        shadowRadius: 4,
        elevation: 6,
        //width: Platform.OS === 'web' ? '100%' : '100%',
        //minWidth: Platform.OS === 'web' ? '300px' : undefined,
        //maxWidth: Platform.OS === 'web' ? '800px' : undefined,
        //alignSelf: 'stretch',

        minWidth: 200, // Ensure a minimum width for smaller screens
        maxWidth: 400, // Set a maximum width if needed
        alignSelf: 'flex-start',
        height: '100%', // Take full height of the parent    
    },
    header: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 16,
        borderBottomWidth: 2,
        borderBottomColor: '#6200ee',
        paddingBottom: 8,
    },
    headerTitle: {
        fontSize: 20,
        fontWeight: 'bold',
        color: '#1a1a1a',
    },
    selectedFiltersContainer: {
        marginBottom: 16,
        borderBottomWidth: 1,
        borderBottomColor: '#e0e0e0',
        paddingVertical: 12,

    },
    selectedFiltersTextTitle: {
        fontSize: 12,
        color: 'black', // Black text color
        fontWeight: 'bold'
    },    
    selectedFiltersText: {
        marginBottom: 4,
        fontSize: 10,
        color: '#7B68EE',
        marginBottom: 4,
        fontWeight: '600',
    },    
    section: {
        marginBottom: 16,
    },
    sectionHeader: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingVertical: 12,
        borderBottomWidth: 1,
        borderBottomColor: '#e0e0e0',
    },
    sectionTitle: {
        fontSize: 14,
        fontWeight: '600',
        color: '#333',
    },
    chevron: {
        fontSize: 12,
        color: 'crimson',
    },
    sectionContent: {
        maxHeight: 200,
        //paddingRight: 16,
        //flexDirection: 'row',
        //flexWrap: 'wrap',
        //marginTop: 8,    
    },
    chip: {
        marginRight: 6,
        marginBottom: 6,
        height: 28, // Make chips smaller
        backgroundColor: 'white', // Default background color
    },
    selectedChip: {
        backgroundColor: '#7B68EE', // Light blue background for selected chips
    },
    chipText: {
        fontSize: 12,
        color: 'black', // Black text color
        fontWeight: 'bold'
    },
    selectedChipText: {
        fontSize: 12,
        color: 'black', // Black text color
        fontWeight: 'bold'

    },  
    textInput: {
        borderWidth: 3,
        borderColor: 'black',
        borderRadius: 4,
        padding: 8,
        marginTop: 8,
        color: 'black',
        backgroundColor: 'white'
    },
    });

export default FilterPanel;