import {
	View,
	Platform,
	Text, Pressable, ImageBackground, Linking, Image, Share, StyleSheet
} from 'react-native'

const Header = () => {
	// Doing this instead of importing with static background pic
		let backgroundImage;
		try {
			backgroundImage = require(`../${process.env.REACT_APP_CATEGORY_BACKGROUND_PATH}`);
		} catch (error) {
			console.error('Error loading background image:', error);
			backgroundImage = null; // Fallback in case the image can't be loaded
		}
	
		let nupeacockLogoImage;
		try {
			nupeacockLogoImage = require(`../${process.env.REACT_APP_NUPEACOCK_LOGO_PATH}`);
		} catch (error) {
			console.error('Error loading nupeacock logo image:', error);
			nupeacockLogoImage = null; // Fallback in case the image can't be loaded
		}
	
		let nupeacockTwitterLogoImage;
		try {
			nupeacockTwitterLogoImage = require(`../${process.env.REACT_APP_NUPEACOCK_TWITTER_LOGO_PATH}`);
		} catch (error) {
			console.error('Error loading twitter logo image:', error);
			nupeacockTwitterLogoImage = null; // Fallback in case the image can't be loaded
		}
	
		let nupeacockFacebookLogoImage;
		try {
			nupeacockFacebookLogoImage = require(`../${process.env.REACT_APP_NUPEACOCK_FACEBOOK_LOGO_PATH}`);
		} catch (error) {
			console.error('Error loading facebook logo image:', error);
			nupeacockFacebookLogoImage = null; // Fallback in case the image can't be loaded
		}
	
		let nupeacockInstagramLogoImage;
		try {
			nupeacockInstagramLogoImage = require(`../${process.env.REACT_APP_NUPEACOCK_INSTAGRAM_LOGO_PATH}`);
		} catch (error) {
			console.error('Error loading instagram logo image:', error);
			nupeacockInstagramLogoImage = null; // Fallback in case the image can't be loaded
		}
	
		let nupeacockSocialshareLogoImage;
		try {
			nupeacockSocialshareLogoImage = require(`../${process.env.REACT_APP_NUPEACOCK_SOCIALSSHARE_LOGO_PATH}`);
		} catch (error) {
			console.error('Error loading socialshare logo image:', error);
			nupeacockSocialshareLogoImage = null; // Fallback in case the image can't be loaded
		}
	
		let nupeacockEmailLogoImage;
		try {
			nupeacockEmailLogoImage = require(`../${process.env.REACT_APP_NUPEACOCK_EMAIL_LOGO_PATH}`);
		} catch (error) {
			console.error('Error loading email logo image:', error);
			nupeacockEmailLogoImage = null; // Fallback in case the image can't be loaded
		}
	
	
		return (
		<ImageBackground source={backgroundImage} style={styles.header} resizeMode="cover">
	
			<View style={styles.headerContent}>
			<View style={styles.socialIcons}>
				<Pressable onPress={() => Linking.openURL('https://nupeacock.com')}>
				<Image source={nupeacockLogoImage} style={styles.icon} />
				</Pressable>
			</View>
			<View style={styles.headerText}>
				<Text style={styles.title}>{process.env.REACT_APP_SITE_NAME}</Text>
				<Text style={styles.subtitle}>{process.env.REACT_APP_SITE_TEXT}</Text>
				<Text style={styles.rider}>**Always check for latest availabilies at store sites!</Text>
			</View>
			<View style={styles.socialIcons}>
				<Pressable onPress={() => Linking.openURL(process.env.REACT_APP_TWITTER_URL)}>
				<Image source={nupeacockTwitterLogoImage} style={styles.icon} />
				</Pressable>
				<Pressable onPress={() => Linking.openURL(process.env.REACT_APP_INSTAGRAM_URL)}>
				<Image source={nupeacockInstagramLogoImage} style={styles.icon} />
				</Pressable>
				<Pressable onPress={() => Linking.openURL(process.env.REACT_APP_FACEBOOK_URL)}>
				<Image source={nupeacockFacebookLogoImage} style={styles.icon} />
				</Pressable>
				<Pressable onPress={() => Linking.openURL('mailto:crengana@nupeacock.com')}>
				<Image source={nupeacockEmailLogoImage} style={styles.icon} />
				</Pressable>
				<Pressable onPress={sharePage}>
				<Image source={nupeacockSocialshareLogoImage} style={styles.icon} />
				</Pressable>
			</View>
			</View>
		</ImageBackground>
		);
	};
	
	const sharePage = () => {
		const shareData = {
			title: process.env.REACT_APP_SITE_TITLE,
			text: process.env.REACT_APP_SITE_TEXT,
			url: process.env.REACT_APP_SITE_URL,
		};
		if (Platform.OS === 'web') {
			if (navigator.share) {
			navigator.share(shareData)
				.then(() => console.log('Successful share'))
				.catch((error) => console.log('Error sharing:', error));
			} else {
			console.log('Web Share API not supported.');
			}
		} else {
			Share.share(shareData)
			.then((result) => {
				if (result.action === Share.sharedAction) {
				console.log('Share was successful');
				}
			})
			.catch((error) => console.log('Error sharing:', error));
		}
	};

    const styles = StyleSheet.create({
        header: {

            width: '100%',
            height: 400, // Adjust height as needed
            //flexDirection: 'row',
            // alignItems: 'center',
            // justifyContent: 'space-between', // Ensures logo is on the left and social icons on the right
            //justifyContent: 'flex-start',
            justifyContent: 'center',
            padding: 10,
          },
          headerContent: {
            flex: 1,
            flexDirection: 'row',
            justifyContent: 'space-between',
            flexWrap: 'wrap', // Allows wrapping on mobile
          },
          logo: {
              height: 60,
              //resizeMode: 'contain',
              flexDirection: 'row',
              justifyContent: 'flex-start',
          },
          icon: {
              height: 30,
              width: 30,
              marginLeft: 10,
              borderRadius: 25,
          },
          socialIcons: {
              flexDirection: 'row',
              justifyContent: 'flex-end',
              marginBottom: 200,
          },
        
          headerText: {
            flex: 1,
            marginLeft: 15, // Adjust as needed
            //justifyContent: 'center',
            //textAlign: 'center',
            //alignItems: 'center',
          },
          title: {
            fontSize: 24, // Adjust to desired size
            color: '#fff00f',
            fontWeight: 'bold',
            textShadow: '1px 1px 5px rgba(0, 0, 0, 0.75)',
            //textShadowColor: 'rgba(0, 0, 0, 0.75)',
            //textShadowOffset: { width: -1, height: 1 },
            //textShadowRadius: 10,
            //textAlign: 'center',
          },
          subtitle: {
            //fontSize: 32, // Adjust to desired size
            fontSize: 20, // Smaller size for mobile, can adjust to 32 for desktop
            color: '#ff0000',
            marginTop: 5,
            fontWeight: 'bold',
            //textShadowColor: 'rgba(0, 0, 0, 0.75)',
            //textShadowOffset: { width: -1, height: 1 },
            //textShadowRadius: 10,
            textShadow: '1px 1px 5px rgba(0, 0, 0, 0.75)',
            //textAlign: 'right',
          },
          rider: {
            //fontSize: 32, // Adjust to desired size
            fontSize: 10, // Smaller size for mobile, can adjust to 32 for desktop
            color: '#ff0000',
            marginTop: 5,
            fontWeight: 'bold',
            //textShadowColor: 'rgba(0, 0, 0, 0.75)',
            //textShadowOffset: { width: -1, height: 1 },
            //textShadowRadius: 10,
            textShadow: '1px 1px 5px rgba(0, 0, 0, 0.75)',
            //textAlign: 'right',
          },
        
          listContainer: {
            paddingHorizontal: 20,
            minWidth: 1000,
          },
    });        

export default Header;