import React, { useState, useEffect, useCallback } from 'react'
import {
	SafeAreaView,
	StyleSheet,
	View,
	Platform,
	ScrollView,
	Dimensions,
	Text, Pressable, Linking  
} from 'react-native'

import {
	Provider as PaperProvider,
	Card,
	Title,
	Paragraph,
	ActivityIndicator,
	Snackbar,
	Button,
	TextInput,
	Portal,
	Dialog
} from 'react-native-paper'

import CryptoJS from 'crypto-js';
import { signInAnonymously } from 'firebase/auth'
import { storage, auth, appCheckInstance } from './firebaseConfig'
import { ref, getDownloadURL } from 'firebase/storage'
import { getToken } from 'firebase/app-check'
import FilterPanel from './components/FilterPanel';
import Header from './components/Header'

const loginUser = async () => {
	try {
	  await signInAnonymously(auth);
	  console.log('User logged in anonymously');
	} catch (error) {
	  console.error('Error with anonymous login:', error);
	}
  };
  
const appCheckToken = await getToken(appCheckInstance, true);

const imagePath = process.env.REACT_APP_FIREBASE_STORAGE_DLD_IMAGE_PATH;
const backupImage = process.env.REACT_APP_LOCAL_BACKUP_IMAGE_PATH;

// Error handling component
const ErrorDialog = ({ visible, message, onDismiss, onRetry }) => (
	<Portal>
		<Dialog visible={visible} onDismiss={onDismiss}>
			<Dialog.Title>Error</Dialog.Title>
			<Dialog.Content>
				<Paragraph>{message}</Paragraph>
			</Dialog.Content>
			<Dialog.Actions>
				<Button onPress={onRetry}>Retry</Button>
				<Button onPress={onDismiss}>Dismiss</Button>
			</Dialog.Actions>
		</Dialog>
	</Portal>
)


const formatPrice = (price) => {
	// Check if price is a number and not zero, or a non-empty string
	if ((typeof price === 'number' && price !== 0) || (typeof price === 'string' && price.trim() !== '' && price !== '0' && price !== '0.0' && price !== 'NA') ) {
	  // If it's a number, format it to 2 decimal places
	  	let formattedPrice = typeof price === 'number' ? price.toFixed(2) : price.trim();
    // Check if the formatted price already starts with a dollar sign
    	if (!formattedPrice.startsWith('$') && !/^[a-zA-Z]/.test(formattedPrice)) {
			formattedPrice = `$${formattedPrice}`;
	  	}
		return formattedPrice;

	}
	return null; // Return null if price is 0 or empty string or NA 
};

const extractPrice = (priceInput) => {
	let priceString;
  
	// Check the type of priceInput and convert to string if necessary
	if (typeof priceInput === 'number') {
	  // If it's a number, convert it directly to a string
	  priceString = priceInput.toString();
	} else if (typeof priceInput === 'string') {
	  // If it's already a string, use it as is
	  priceString = priceInput;
	} else {
	  // If it's neither, return null or 0
	  return null; // or return 0;
	}
  
	// Use regex to extract numbers (including decimals)
	const numberString = priceString.match(/[\d,.]+/g);
  
	// If no numbers found, return null or 0
	if (!numberString) {
	  return null; // or return 0;
	}
  
	// Join the array of numbers (in case there are multiple matches)
	const numericValue = numberString.join('');
  
	// Replace commas with empty string (if using comma as thousand separator)
	const sanitizedValue = numericValue.replace(/,/g, '');
  
	// Convert to float
	const floatValue = parseFloat(sanitizedValue);
  
	// Check if the value is a valid number
	if (isNaN(floatValue)) {
	  return null; // or return 0;
	}
  
	// Return the float value rounded to 2 decimal places
	return parseFloat(floatValue.toFixed(2));
};


function generateHash(inputString, length = 10) {
	let md5Hash = CryptoJS.MD5(inputString).toString();
	return md5Hash.slice(0, length);
}
  
function generateFilename(categoryStore, productName, hashStr) {
	let cleanName = productName.replace(/[^a-zA-Z0-9\s-]/g, '').toLowerCase().trim().replace(/\s+/g, '-');

	//console.log(`cleanName:`, cleanName)

	let namePart = cleanName.slice(0, 10);

	//console.log(`namePart:`, namePart)
	//console.log(`imgefile name: ${categoryStore}-${namePart}-${hashStr}.png`)

	return `${categoryStore}-${namePart}-${hashStr}.png`;
}


const ProductCard = ({ product }) => {

	const [imageUrl, setImageUrl] = useState(null);

	useEffect(() => {
	  const fetchImageUrl = async () => {
		if (product['Product Image']) {
		  try {
			const appCheckToken = await getToken(appCheckInstance, true);
			const hashStr = generateHash(product['Product Image']);
			const filename = generateFilename(`${product.category}-${product.vendorName}`, product['Product Name'], hashStr);
			//console.log(`In productcard useeffct; filename:`, filename)
			//console.log(`In productcard useeffct; imagePath:`, imagePath)

			const imageRef = ref(storage, `${imagePath}/${product.category}-${product.vendorName}/${filename}`);
			//console.log(`In productcard useeffct; imageRef:`, imageRef)

			const url = await getDownloadURL(imageRef, {
			  customHeaders: { 'X-Firebase-AppCheck': appCheckToken.token }
			});
			//console.log(`In productcard useeffct; url:`, url)
			setImageUrl(url);
		  } catch (error) {
			//console.error('Error fetching image URL:', error);
			setImageUrl(backupImage); // fallback to placeholder
		  }
		} else {
		  setImageUrl(backupImage); // fallback to placeholder
		}
	  };
  
	  fetchImageUrl();
	}, [product]);

	if (!product['Product Name']) return null

    const handlePress = () => {
        if (product['Product Url']) {
            Linking.openURL(product['Product Url']);
        } else {
            console.warn('No URL available for this product');
        }
    };


	return (
        <Pressable onPress={handlePress}>

		<Card style={styles.card}>
		    <View style={styles.cardHeader}>

				<Card.Cover
          			//source={imageUrl ? { uri: imageUrl } : require('./assets/bf2024_all/blackfriday-128x128.png')} 
          			//source={imageUrl ? { uri: imageUrl } : require(backupImage)}
					source={imageUrl ? { uri: imageUrl } : { uri: backupImage }}

					style={styles.cardImage}
				/>

				<View style={styles.priceContainer}>
					<View style={styles.priceContent}>
						{formatPrice(product["Product Price"]) && (

							<Text style={styles.price}>{product['Product Price']}</Text>
						)}
						{formatPrice(product['Product Original Price']) && (
								<Text style={styles.originalPrice}>
									{product['Product Original Price']}
								</Text>
						)}
						{formatPrice(product['Product Discount']) && (
							<View style={styles.discountBadge}>
								<Text style={styles.discountText}>Discount:</Text>
								<Text style={styles.discountText}>
									{formatPrice(product['Product Discount'])}
								</Text>
							</View>
						)}
					</View>
				</View>
			</View>
			<Card.Content>
				<Title numberOfLines={1} style={styles.vendorName}>
					{product.vendorName}
				</Title>

				<Title numberOfLines={2} style={styles.productName}>
					{product['Product Name']}
				</Title>
				{product['Product Description'] &&
					product['Product Description'] !== 'NA' && (
						<Paragraph numberOfLines={2} style={styles.description}>
							{product['Product Description']}
						</Paragraph>
					)}
			</Card.Content>
		</Card>
        </Pressable>

	)
}


const App = () => {
	const [products, setProducts] = useState([])
	const [loading, setLoading] = useState(true)
	const [error, setError] = useState(null)
	const [filters, setFilters] = useState({
		vendor: [],    // Changed from '' to []
		category: [],  // Changed from '' to []
		maxPrice: ''
	});

	const fetchProductData = useCallback(async () => {
		setLoading(true)
		setError(null)

		try {
			// Firebase Authentication
			await signInAnonymously(auth)
			const appCheckToken = await getToken(appCheckInstance, true)

			const indexPath = process.env.REACT_APP_FIREBASE_STORAGE_INDEX_PATH
			const indexRef = ref(storage, indexPath)
			const indexUrl = await getDownloadURL(indexRef, {
				customHeaders: { 'X-Firebase-AppCheck': appCheckToken.token }
			})
			//console.log(`indexUrl:`, indexUrl)
			const indexResponse = await fetch(indexUrl, {
				mode: 'cors',
				headers: { 'X-Firebase-AppCheck': appCheckToken.token }
			})

			if (!indexResponse.ok) {
				throw new Error(`HTTP error! status: ${indexResponse.status}`)
			}

			const indexData = await indexResponse.json()
			const productPromises = indexData.files.map(async filePath => {
				const category = filePath.split('/')[3];
				const vendorName = filePath.split('/')[4];
				//console.log(`category`, category)
				//console.log(`vendorName`, vendorName)
				//console.log(`filters.category`, filters.category)
				//console.log(`filters.vendor`, filters.vendor)
				
				// Only fetch products if they match the selected filter
				if (
				  	(filters.category.length === 0 || filters.category.includes(category)) &&
				  	(filters.vendor.length === 0 || filters.vendor.includes(vendorName))
				) 	{				
					try {
						const fileRef = ref(storage, filePath)
						const fileUrl = await getDownloadURL(fileRef, {
							customHeaders: { 'X-Firebase-AppCheck': appCheckToken.token }
						})
						const response = await fetch(fileUrl, {
							mode: 'cors',
							headers: { 'X-Firebase-AppCheck': appCheckToken.token }
						})

						if (!response.ok) {
							throw new Error(`Failed to fetch ${filePath}`)
						}

						const products = await response.json()
						//console.log(`filepath:`, filePath)
						const vendorName = filePath.split('/')[4]
						//console.log(`category:`, filePath.split('/')[3])
						const category = filePath.split('/')[3]
						return products.map(product => ({
							...product,
							vendorName,
							category
						}))
					} catch (error) {
						console.error(`Error fetching ${filePath}:`, error)
						return []
					}
				} else {
					return [];
				}					
			})

//			const allProducts = (await Promise.all(productPromises))
//				.flat()
//				.filter(product => product['Product Name'])

//			setProducts(allProducts)
// Setting only filtered products instead of all products on initial page loads
			const filteredProducts = (await Promise.all(productPromises))
				.flat()
				.filter((product) => product['Product Name']);

			setProducts(filteredProducts);

		} catch (error) {
			console.error('Error fetching product data:', error)
			setError(error.message)
		} finally {
			setLoading(false)
		}
	}, [])

	useEffect(() => {
		fetchProductData()
	}, [fetchProductData])

	useEffect(() => {
		const setDefaultFilters = async () => {
		  try {
			// Fetch initial metadata to determine available categories and vendors
			await signInAnonymously(auth);
			const appCheckToken = await getToken(appCheckInstance, true);
			const indexPath = process.env.REACT_APP_FIREBASE_STORAGE_INDEX_PATH;
			const indexRef = ref(storage, indexPath);
			const indexUrl = await getDownloadURL(indexRef, {
			  customHeaders: { 'X-Firebase-AppCheck': appCheckToken.token }
			});
			const indexResponse = await fetch(indexUrl, {
			  mode: 'cors',
			  headers: { 'X-Firebase-AppCheck': appCheckToken.token }
			});
	  
			if (!indexResponse.ok) {
			  throw new Error(`HTTP error! status: ${indexResponse.status}`);
			}
	  
			const indexData = await indexResponse.json();
			const categories = Array.from(new Set(indexData.files.map(filePath => filePath.split('/')[3])));
			const vendors = Array.from(new Set(indexData.files.map(filePath => filePath.split('/')[4])));
	  
			// Set default filters to the first available category and vendor
			setFilters({
			  category: categories.length > 0 ? [categories[0]] : [],
			  vendor: vendors.length > 0 ? [vendors[0]] : [],
			  maxPrice: ''
			});
		  } catch (error) {
			console.error('Error setting default filters:', error);
		  }
		};
	  
		setDefaultFilters();
	  }, []);
	  

 	// Filtering Logic Updated for Array Handling
 	const filterProducts = (products, filters) => {
		return products.filter(product => {
			const matchesVendor = filters.vendor.length === 0 || filters.vendor.includes(product.vendorName);
			const matchesCategory = filters.category.length === 0 || filters.category.includes(product.category);
			const matchesPrice = !filters.maxPrice || extractPrice(product['Product Price']) <= parseFloat(filters.maxPrice);
			return matchesVendor && matchesCategory && matchesPrice;
		});
  	};	
	const filteredProducts = filterProducts(products, filters)

	//console.log(`In App.js, filters, product.category`, filters, products.vendorName)
	
	return (
		<PaperProvider>
			<SafeAreaView style={styles.container}>
				<Header/>
				<View style={styles.mainContent}>
					<FilterPanel filters={filters} onFilterChange={setFilters}  products={products} />

					{loading ? (
						<View style={styles.loadingContainer}>
							<ActivityIndicator size='large' />
							<Text style={styles.loadingText}>Please wait..products loading!</Text>
						</View>
					) : filteredProducts.length === 0 ? (
						<View style={styles.loadingContainer}>
							<Text style={styles.loadingText}>No products available for current filter selections.</Text>
						</View>						
					) : (
						//<View style={styles.mainContent}>
						//<FilterPanel filters={filters} onFilterChange={setFilters} products={products} />
						<ScrollView style={styles.productsContainer}>
						  <View style={styles.productsGrid}>
							{filteredProducts.map((product, index) => (
							  <View key={index} style={styles.productWrapper}>
								<ProductCard product={product} />
							  </View>
							))}
						  </View>
						</ScrollView>
					  //</View>
					)}
				</View>

				<ErrorDialog
					visible={!!error}
					message={error || 'An error occurred'}
					onDismiss={() => setError(null)}
					onRetry={fetchProductData}
				/>
			</SafeAreaView>
		</PaperProvider>
	)
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: '#f5f5f5'
	},
	mainContent: {
		flex: 1,
		marginTop: 10,
		flexDirection: Platform.OS === 'web' ? 'row' : 'column'
	},
	/*
	filterPanel: {
		width: Platform.OS === 'web' ? 300 : '100%',
		padding: 16,
		backgroundColor: '#fff',
		borderRightWidth: Platform.OS === 'web' ? 1 : 0,
		borderBottomWidth: Platform.OS === 'web' ? 0 : 1,
		borderColor: '#e0e0e0'
	},
	filterInput: {
		marginBottom: 16
	},
	*/
	productsContainer: {
		flex: 1
	},
	productsGrid: {
		flexDirection: 'row',
		flexWrap: 'wrap',
		padding: 8
	},
	productWrapper: {
		width:
			Platform.OS === 'web'
				? Dimensions.get('window').width > 1200
					? '20%'
					: '25'
				: '50%',
		padding: 8
	},
	card: {
		height: '100%',
		backgroundColor: 'lightblue',

	},
	cardHeader: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'flex-start',
		padding: 8,
	},
	cardImage: {
		flex: 1,
		height: 100,
		width:100,		
		aspectRatio: 1, // Keeps the image square
		backgroundColor: 'lightblue',
	},
	priceWrapper: {
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center',
	},	
	priceContainer: {

		flex: 1,
		height: 100, // Match the height of cardImage
		justifyContent: 'center',
		alignItems: 'center',
	},
	priceContent: {
		alignItems: 'center',
	  },		
	price: {
		fontSize: 14,
		fontWeight: 'bold',
	},
	originalPrice: {
		fontSize: 12,
		textDecorationLine: 'line-through',
		color: '#999'
	},
	discountBadge: {
		position: 'relative',
		top: 8,
		right: 8,
		backgroundColor: '#ff4444',
		padding: 4,
		borderRadius: 4
	},
	discountText: {
		color: '#fff',
		fontSize: 10
	},

	vendorName: {
		fontSize: 12,
		lineHeight: 15,
		fontWeight: 'bold'
	},

	productName: {
		fontSize: 12,
		lineHeight: 15,
		marginTop: 4,

	},
	description: {
		fontSize: 12,
		color: '#666',
		marginTop: 4,

	},
	loadingContainer: {
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center'
	},
	loadingText: {
		marginTop: 16,
		fontSize: 24,
		fontWeight: 'bold',
		textAlign: 'center',
		color: 'red',
	},
})

export default App
